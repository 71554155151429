
import { mapGetters, mapActions } from 'vuex';
import LOCALE_DESTINATION from '~/assets/constants/LOCALE_DESTINATION_COUNTRY';
import LOCALE_ORIGIN from '~/assets/constants/LOCALE_ORIGIN_COUNTRY';
import StelaInputSelect from '~/components/common/StelaInputSelect';
import { SHIPPING_METHODS_ARRAY } from '~/assets/constants/SHIPPING_METHODS.js';

export default {
  name: 'StelaPriceCalculatorPanel',
  components: {
    StelaInputSelect,
  },
  props: {
    address: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      plansData: [],
      calculateEstimateNotFound: false,
      allShippingMethods: SHIPPING_METHODS_ARRAY,
      lbsToKgDivisor: 2.2046,
      loading: false,
      destinationCountry: null,
      originCountry: null,
      weight: null,
      kg: 0,
      height: 0,
      width: 0,
      length: 0,
      preferredCurrency: 'USD',
      recipientState: '',
      deliveryChoice: null,
      selectedFreightOption: null,
      selectedPlanOption: null,
      showExtraLargeModal: false,
      deliveryFee: {
        charge: 0,
        currency: 'USD',
        display: '0',
        mainUnit: 0,
        subUnit: 0
      },
      shippingFee: {
        charge: 0,
        currency: 'USD',
        display: '0',
        mainUnit: 0,
        subUnit: 0
      },
      totalFee: {
        charge: 0,
        currency: 'USD',
        display: '0',
        mainUnit: 0,
        subUnit: 0
      },
      showExtraLargeShipmentModal: false,
    };
  },
  computed: {
    ...mapGetters({
      supportedDestinationCountriesData: 'addressbook/supportedDestinationCountriesData',
      supportedOriginCountriesData: 'addressbook/supportedOriginCountriesData',
      statesCitiesDataIsLoading: 'addressbook/statesCitiesDataIsLoading',
      statesData: 'addressbook/statesData',
      allStatesData: 'addressbook/allStatesData',
      addresses: 'addressbook/addresses',
      plansDataIsLoading: 'plan/plansDataIsLoading',
      plansData: 'plan/plansData',
    }),
    recipientStatesData () {
      return (this.destinationCountry?.value && this.allStatesData[this.destinationCountry?.value]) || this.statesData;
    },
    recipientStates () {
      return this.recipientStatesData.map(this.mapSelectOption);
    },
    deliveryChoicePickup () {
      if (this.selectedDeliveryType === this.DELIVERY_CHOICE_HOME) {
        return this.recipientState === null;
      } else {
        return this.recipientState;
      }
    },
    freightShippingGroupOptions () {
      return this.allShippingMethods.map(frieght => ({
        label: frieght.key.replace('_', ' '),
        value: frieght.key
      }));
    },
    allUserPlans () {
      return this.plansData.filter(plan => plan.name);
    },
    userPlan () {
      return this.allUserPlans.map(plan => ({
        label: plan.name,
        value: plan.code
      }));
    },
    weightLimitConverter () {
      const weightLimits = {
        kg: { min: '1', max: '999999999999999' },
      };
      return weightLimits;
    },
    selectedDeliveryType () {
      return this.deliveryChoice?.value;
    },
    formData () {
      return {
        weight: this.weight,
        originCountry: this.originCountry,
        destinationCountry: this.destinationCountry,
        freight_mode: this.selectedFreightOption?.value,
        plan_code: this.selectedPlanOption?.value,
        recipientState: this.recipientState?.value,
        deliveryChoice: this.deliveryChoice,
      };
    },
    supportedDestinationCountryOptions () {
      return Object.values(this.excludeFlaggedCountries(LOCALE_DESTINATION.COUNTRIES, this.featureFlaggedDestinationCountries)).map(({ alpha2, emoji, name }) => ({
        label: `${emoji} ${name}`,
        value: alpha2,
      }));
    },
    supportedOriginCountryOptions () {
      return Object.values(this.excludeFlaggedCountries(LOCALE_ORIGIN.COUNTRIES, this.featureFlaggedOriginCountries)).map(({ alpha2, emoji, name }) => ({
        label: `${emoji} ${name}`,
        value: alpha2,
      }));
    },
    deliveryChoices () {
      if (this.destinationCountry && this.destinationCountry.value === LOCALE_DESTINATION.COUNTRY.GH.alpha2) {
        return [
          {
            label: 'Ghana Pickup',
            value: this.DELIVERY_CHOICE_PICKUP,
          },
          {
            label: 'Home Delivery',
            value: this.DELIVERY_CHOICE_HOME,
          },
        ];
      }

      return [
        {
          label: 'Home delivery',
          value: this.DELIVERY_CHOICE_HOME,
        },
        {
          label: 'Pickup',
          value: this.DELIVERY_CHOICE_PICKUP,
        },
      ];
    },
    getPreferredCurrencySymbol () {
      if (this.preferredCurrency === 'NGN') {
        return '₦';
      }
      if (this.preferredCurrency === 'USD') {
        return '$';
      }
      return '';
    },
    formattedTotalAmount () {
      return new Intl.NumberFormat('en-US').format(this.totalFee.mainUnit);
    },
  },
  watch: {
    destinationCountry () {
      this.deliveryChoice = this.deliveryChoices[0];
      this.selectedPlanOption = this.userPlan[0];
    },
  },
  mounted () {
    this.destinationCountry = this.supportedDestinationCountryOptions.find(({ value }) => {
      return value === LOCALE_DESTINATION.COUNTRY.NG.alpha2;
    });

    this.originCountry = this.supportedOriginCountryOptions.find(({ value }) => {
      return value === LOCALE_ORIGIN.COUNTRY.US.alpha2;
    });
    this.fetchPlans();
  },
  created () {
    this.unwatch = this.$watch(
      'formData',

      this.debounce(() => {
        if (this.weight && this.deliveryChoice.label && this.deliveryChoice.value) {
          this.estimatePrice();
        }
      }, 1000
      )
    );
    if (this.address) {
      this.setFormFields();
    }
    if (this.address && this.address.country) {
      this.fetchStatesCities({ country: this.address.country });
      return;
    }
    this.fetchStatesCities({ country: 'NG' });
  },
  beforeDestroy () {
    this.unwatch();
  },
  methods: {
    ...mapActions({
      fetchSubscriptions: 'subscription/fetchSubscriptions',
      fetchStatesCities: 'addressbook/fetchStatesCities',
      fetchPlans: 'plan/fetchPlans',
    }),
    async estimatePrice () {
      this.loading = true;
      this.calculateEstimateNotFound = false;
      try {
        const { deliveryChoice } = this.interpretDeliveryType(this.deliveryChoice?.value);
        let planCode = this.selectedPlanOption?.value;
        if (!planCode) {
          planCode = this.userPlan.find(plan => plan.name[0]);
        }
        const requestData = {
          pickup_country: this.destinationCountry?.value,
          origin_country: this.originCountry?.value,
          weight: Number(Number(this.weight).toFixed(2)),
          width: this.width,
          height: this.height,
          freight_mode: this.selectedFreightOption?.value,
          length: this.length,
          plan_code: planCode,
          delivery_choice: deliveryChoice,
          origin_warehouse: 'US-TX-HOU',
          delivery_address: {
            country: this.destinationCountry?.value,
            state: this.recipientState?.value,
            name: 'UNKNOWN',
            phone: 'UNKNOWN',
            street: 'UNKNOWN',
            city: 'UNKNOWN'
          },
        };
        const { data } = await this.$api.shipping.orders.estimate({
          data: requestData,
        });
        if (data.payload) {
          this.deliveryFee = data.payload.estimate.deliveryFee;
          this.shippingFee = data.payload.estimate.shippingFee;
          this.totalFee = data.payload.estimate.total;
        }
      } catch (error) {
        this.calculateEstimateNotFound = true;
      } finally {
        this.loading = false;
      }
    },
    async fetchPlans () {
      this.fetchingPlans = true;
      try {
        const { data } = await this.$api.subscription.plans.list({});
        this.plansData = data?.payload?.plans || [];
      } catch (error) {
        this.handleServerError(error);
      } finally {
        this.fetchingPlans = false;
      }
    },
    interpretDeliveryType (deliveryType) {
      const deliveryChoiceMap = {
        [this.DELIVERY_CHOICE_HOME]: this.DELIVERY_CHOICE_HOME,
        [this.DELIVERY_CHOICE_PICKUP]: this.DELIVERY_CHOICE_PICKUP,
      };

      const deliveryChoice = deliveryChoiceMap[deliveryType];
      const addressIsWithinLagos = deliveryType === this.DELIVERY_CHOICE_HOME_LAGOS;
      return { deliveryChoice, addressIsWithinLagos };
    },
  },
};
